<template>
<div>

           <CCard class="cards">
            <CCardHeader>
              <CRow>
                <CCol col="6" class="styleHeader">{{$t('message.showUser')}} </CCol>
                <!-- <CCol col="6" class="styleHeader">{{$t('message.totalCachouts')}} :{{sumCachout}} </CCol> -->
              </CRow>
            </CCardHeader>
            <div class="body mt-3">
                <div>
       <div class="row">
        <div class="col-md-6">
         <div style="background-color: white; display:flex;flex-direction:row;">
            <div style="flex:1; ">

              <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>{{$t('message.userName')}} </th>
                                        <td>{{ user.name}}</td>
                                    </tr>
                                    <tr>
                                        <th >{{$t('message.userEmail')}}</th>
                                        <td>{{ user.email }}</td>
                                    </tr>
                                     <tr>
                                        <th>  {{$t('message.role')}} </th>
                                        <td>{{  user.roles[0].name  }}</td>
                                    </tr>
                                </tbody>

                            </table>

                  </div>
         </div>
           <div class="row" >
             <CCol col="12" class="styleHeader">{{ $t('message.permission') }}  </CCol>
            <div class="col-md-12 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.id') }}   </th>
                    <th> {{ $t('message.permissionName') }}  </th>
                     <th>  {{ $t('message.guardName') }}  </th>
                  </tr>
                   <tr v-for=" permission in user.roles[0].permissions" :key="permission.id">
                    <td>{{ permission.id }}</td>
                    <td>{{ permission.name.replace(":"," ") }}</td>
                     <td>{{ permission.guard_name }}</td>
                  </tr>
                </table>
            </div>
          </div>
        </div>

      </div>
                </div>
                 <CButton color="btn btn-primary" @click="goBack">{{$t('message.back')}} </CButton>
            </div>
        </CCard>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Showuser',
  data () {
    return {
      user: [],
      permissions: [],
      roles: [],
      moment: moment
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}users/${this.$route.params.id}`)
      .then((response) => {
        this.user = response.data.data
      })
    // this.$http
    //   .get(`${this.$hostUrl}user/${this.$route.params.id}`)
    //   .then((response) => {
    //     this.permissions = response.data.data.roles
    //   })
  },
  methods: {
    goBack () {
      this.customersOpened ? this.$router.go(-1) : this.$router.push({ path: '/users' })
    }
  }
}
</script>
<style scoped>
span{
  font-size: 20px;
  margin-top: 10px;
}
table,td ,th{
    border: 1px solid #ddd;
  text-align: right;
}

th, td {
  padding: 15px;
}
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
  .cont{
    display: inline;
    margin: 20px;
  }
  button{
    margin: 30px;
  }
  .left{
    margin: 30px;
  }
</style>
